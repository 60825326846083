@font-face {
font-family: '__maisonNeueBook_5d1e63';
src: url(/_next/static/media/a6144c9a36a971cb.p.woff2) format('woff2');
font-display: optional;
font-weight: 400;
}@font-face {font-family: '__maisonNeueBook_Fallback_5d1e63';src: local("Arial");ascent-override: 97.67%;descent-override: 33.08%;line-gap-override: 0.00%;size-adjust: 101.57%
}.__className_5d1e63 {font-family: '__maisonNeueBook_5d1e63', '__maisonNeueBook_Fallback_5d1e63';font-weight: 400
}.__variable_5d1e63 {--font-maison-neue-book: '__maisonNeueBook_5d1e63', '__maisonNeueBook_Fallback_5d1e63'
}

@font-face {
font-family: '__maisonNeueExtendedDemi_3b454a';
src: url(/_next/static/media/be790e1283be9bb5.p.woff2) format('woff2');
font-display: optional;
font-weight: 500;
}@font-face {font-family: '__maisonNeueExtendedDemi_Fallback_3b454a';src: local("Arial");ascent-override: 83.77%;descent-override: 28.38%;line-gap-override: 0.00%;size-adjust: 118.41%
}.__className_3b454a {font-family: '__maisonNeueExtendedDemi_3b454a', '__maisonNeueExtendedDemi_Fallback_3b454a';font-weight: 500
}.__variable_3b454a {--font-maison-neue-extended-demi: '__maisonNeueExtendedDemi_3b454a', '__maisonNeueExtendedDemi_Fallback_3b454a'
}

@font-face {
font-family: '__maisonNeueMonoRegular_d3a03d';
src: url(/_next/static/media/02cc68ca865506d1.p.woff2) format('woff2');
font-display: optional;
font-weight: 400;
}@font-face {font-family: '__maisonNeueMonoRegular_Fallback_d3a03d';src: local("Arial");ascent-override: 69.64%;descent-override: 23.59%;line-gap-override: 0.00%;size-adjust: 142.45%
}.__className_d3a03d {font-family: '__maisonNeueMonoRegular_d3a03d', '__maisonNeueMonoRegular_Fallback_d3a03d';font-weight: 400
}.__variable_d3a03d {--font-maison-neue-mono-regular: '__maisonNeueMonoRegular_d3a03d', '__maisonNeueMonoRegular_Fallback_d3a03d'
}

